import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Brad Fockler | Freelance Web Developer Vancouver',
  lang: 'en',
  description: 'Web Developer – Shopify, WordPress, and JavaScript Specialist',
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Brad Fockler',
  subtitle: "I'm a web developer",
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I am a graduate from the University of Victoria's Computer Science program, and I currently work for DUER as a Web Developer.",
  paragraphTwo:
    "I specialize in creating pixel-perfect websites that focus on an incredible user experience. With over 5 years of experience, I've had the opportunity to build and launch websites on a wide variety of platforms including Shopify, WordPress, Squarespace, and more advanced JavaScript libraries such as React and Vue.",
  paragraphThree:
    "I'm always available to take on freelance work, so feel free to reach out below.",
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'duer.png',
    title: 'DUER',
    info: 'I currently work at DUER.',
    info2:
      'My job involves improving our Canadian and US e-comm stores, ensuring our ERP integration is running smoothly, and ETLing our sales and marketing data into our warehouse to analyze.',
    url: 'https://duer.ca',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'bn.png',
    title: 'Bailey Nelson',
    info: 'I previously managed the 4 international e-comm websites at Bailey Nelson.',
    info2:
      'My acheivements there include building a product flow to allow the user to customize every aspect of their glasses and rebuilding the store locator and booking widget to allow for better tracking of customer acquisition.',
    url: 'https://baileynelson.com',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'canucks.png',
    title: 'Vancouver Canucks',
    info:
      'I began as the Web Intern and after 4 months was promoted to Web Developer at Canucks Sports & Entertainment.',
    info2:
      'During my tenure, I launched over 20 websites for the Canucks and Aquilini portfolio, including the migration of canucks.com to a modern, mobile-friendly website.',
    url: 'https://www.nhl.com/canucks',
    repo: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Need a new website?',
  btn: 'Talk To Me',
  email: 'brad@fockler.ca',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/bradfockler/',
    },
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:brad@fockler.ca',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/bfockler',
    },
  ],
};
